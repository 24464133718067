import React, { useEffect, useRef } from 'react';
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from 'layouts/Main';
//= Components
import TopNav from 'components/Navbars/TopNav-7';
import Navbar from "components/Navbars/ITCreativeNav";

import Form from 'components/Contact/Form';
import Map from 'components/Contact/Map';
import Footer from 'components/Saas/Footer';
import SideMenu from 'components/ITCreative/SideMenu';

const PageContact5 = () => {
  const navbarRef = useRef(null);

  useEffect(() => {
    navbarScrollEffect(navbarRef.current, true);
  }, [navbarRef]);

  return (
    <MainLayout>
      {/* <TopNav  /> */}
      <SideMenu />
      <Navbar navbarRef={navbarRef} />
      <main className="contact-page style-5">
      
        <Form style="5" />
        <Map />
      </main>
      <Footer noWave />
    </MainLayout>
  )
}

export const Head = () => {
  return (
    <>
      <title>Contact</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  )
}

export default PageContact5;