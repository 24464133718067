import React from 'react';

const Map = () => {
  return (
    <div className="map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.8276122135117!2d7.478229975827353!3d9.079464188195258!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0af8053f313f%3A0x420fc437bf04b26!2s797%20Adetokunbo%20Ademola%20Cres%2C%20Wuse%202%2C%20Abuja%20900288%2C%20Federal%20Capital%20Territory!5e0!3m2!1sen!2sng!4v1727156226205!5m2!1sen!2sng" height="500" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}

export default Map

